import { Message, RunnerClientSessionData } from "@ea/shared_types/next/ea.runner.types";
import {
  CommandResult,
  ExecutionApi,
  ScriptData,
  UsedVariable,
} from "@ea/shared_types/runner.common.types";
import {
  AdditionalJobParams,
  CHECK_REFERENCE_TYPE,
  CodeValue,
  EAFile,
  ExternalScript,
  LabelParams,
  PlainObject,
  PlatfromElementMetadata,
  PlayerStep,
  Project,
  RecorderStep,
  ResolversData,
  Script,
  Step,
  VirtualUser,
  XPATH_SEARCH_TYPE,
} from "@ea/shared_types/types";
import type { Frame, Page } from "puppeteer-core";
import { FormChunkApi } from "./commands.api";
import { getGlobalObject } from "./utils";
import { RunnerScriptData, RunnerStep } from "@ea/shared_types/next/ea.runner.next.types";

export type EA_INTERNAL = {
  platformPreloaders: Record<string, PlatformPreloader>;
  platformConditions: Record<string, () => boolean>;
  currentPlatform: Platform | undefined;
  defaultPlatform: Platform;
  mode: RUNNER_MODES | undefined;
  platforms: (Platform | PlatformWeb | PlatformServer)[];
  events: {
    type: (element: HTMLElement, text: string) => void;
    click: (element: HTMLElement) => void;
    fullClick: (element: HTMLElement) => void;
  };
  rules: {
    packs: Pack<RulesPack>;
  };
  commands: {
    packs: Pack<CommandPack>;
    api: CommandsAPI;
    translations: any;
  };
  lookForFunction?: (entryObject: object, functionNamePattern: string) => string[];
};

export function initDefaults() {
  const global = getGlobalObject();
  if (!global.EA_INTERNAL) {
    const defaultValue = {
      platformConditions: {},
      platformPreloaders: {},
      platforms: [],
      mode: undefined,
      rules: {
        packs: {},
      },
      commands: {
        packs: {},
        api: {},
        translations: {},
      },
    };

    if (typeof window !== "undefined") {
      // @ts-ignore
      global.EA_INTERNAL = defaultValue;
    }

    if (typeof global !== "undefined") {
      // @ts-ignore
      global.EA_INTERNAL = defaultValue;
    }
  }
}

export type CommandsRunnerAPI = {};
export type CommandsWebAPI = {};
export type CommandsServerAPI = {};

export type CommandsAPI = CommandsRunnerAPI | CommandsWebAPI | CommandsServerAPI;

export type CommandDefinitionBaseType = { value: any; id: any; version: any };

export type CommandDefinitionBase<K extends CommandDefinitionBaseType = CommandDefinitionBaseType> =
  {
    id: K["id"];
    // TODO: IT should be required but then we need to define it in every command creation in commands.base files
    // figure out how to do it
    platformId?: string;
    // Mark this option if you don't want to log a command value to the logs
    isSensitiveData?: boolean;
    // Implement getInitialValue if there is a possibility to create a command entity from a Panel
    getInitialValue?: (data: {
      project: Project;
      script: Script;
    }) => Partial<K["value"]> | undefined;
    getCodeValues?: (value: K["value"]) => CodeValue[];
    getLabel?: (step: Step<K>) => { label: string; labelKey: string };
    getLabelParams?: LabelParamsFunction<K>;
  };

export type ExecutionFunction<K extends CommandDefinitionBaseType = CommandDefinitionBaseType> = (
  executionApi: ExecutionApi,
  step: PlayerStep<K>,
  variableCalculator: {
    calculateValue: (code: CodeValue, usedVariablesIds: UsedVariable[], obfuscate?: boolean) => any;
    calculateVariable: (
      variableId: UsedVariable,
      code: CodeValue,
      usedVariablesIds: UsedVariable[],
    ) => any;
  },
  sessionData: RunnerClientSessionData,
  scriptData: ScriptData,
) => Promise<CommandResult | void>;

export type VersionedExecutionFunction<
  K extends CommandDefinitionBaseType = CommandDefinitionBaseType,
> = {
  from: string;
  to: string;
  execute: ExecutionFunction<K>;
};

export type LabelParamsFunction<K extends CommandDefinitionBaseType = CommandDefinitionBaseType> = (
  step: PlayerStep<K> | RecorderStep<K> | Step<K>,
) => LabelParams; // TODO can we make it specific?

export type CommandDefinitionRunner<
  K extends CommandDefinitionBaseType = CommandDefinitionBaseType,
> = {
  execute: ExecutionFunction<K> | VersionedExecutionFunction<K>[];
} & CommandDefinitionBase<K>;

type FormChunkComponents = Record<string, any>; // form & ui components
export type ValidationError = { values?: any; errors?: any };
type ValidationResponse = { values?: any; errors?: any } | void | undefined | null;
type TranslationFunction = (key: string) => string;

export type CommandDefinitionWeb<K extends CommandDefinitionBaseType = CommandDefinitionBaseType> =
  {
    createableManually?: {
      text: string;
      icon: any;
    };
    disableQuickEdit?: boolean;
    getFormChunk: (
      api: FormChunkApi,
      components: FormChunkComponents,
      t: TranslationFunction,
    ) => () => any; // reactnode
    validate?: (
      step: Step<K>,
      t: TranslationFunction,
    ) => ValidationResponse | Promise<ValidationResponse>; // WRITE BETTER TYPES, CAST ENTIRE OBJECT TO [KEY]: STRING
  } & CommandDefinitionBase<K>;

export type CommandDefinitionServer<
  K extends CommandDefinitionBaseType = CommandDefinitionBaseType,
  Z extends string = any,
> = {
  getMigrations: () => Record<Z, (steps: Step<K>[]) => Promise<Step<K>[]>>;
  onExport?: (step: Step<K>, destinationProject?: Project) => Step;
  includeInScriptTemplate?: boolean;
  onExportFilePack?: (step: Step<K>, zipFolder: any) => Promise<any>;
  getFiles?: (step: Step<K>) => EAFile[];
  onClone?: (
    step: Step<K>,
    maps: {
      variablesMap: { [key: number]: number };
      stepsMap?: { [key: number]: number };
      scriptsMap?: { sourceId: number; destId: number };
      globalVariablesMap?: { [key: number]: number };
    },
    config: { fileDest: string; fileSrc: string },
    utils: { addFileNameQuantifier: (fileName: string) => string },
    destinationProject?: Project,
  ) => Promise<Step | void> | Step;
  checkReferences?: (
    step: Step<K>,
    toCheck: {
      variableId?: number;
      stepId?: number;
      sheetNames?: string[];
      globalVariableId?: number;
      systemDefinitionId?: number;
      virtualUserId?: number;
    },
  ) => {
    [CHECK_REFERENCE_TYPE.STEP_REFERENCE]: boolean;
    [CHECK_REFERENCE_TYPE.VARIABLE_REFERENCE]: boolean;
    [CHECK_REFERENCE_TYPE.GLOBALVARIABLE_REFERENCE]: boolean;
    [CHECK_REFERENCE_TYPE.DATASOURCE_REFERENCE]: boolean;
    [CHECK_REFERENCE_TYPE.VIRTUAL_USER_REFERENCE]: boolean;
    [CHECK_REFERENCE_TYPE.SYSTEM_DEFINITION_REFERENCE]: boolean;
  };
} & CommandDefinitionBase<K>;

type Pack<T> = {
  [platformId: string]: T;
};

export type RulesPack = {
  version: string;
  id: string;
  rules: Rule[];
};

export type CommandPack<T extends CommandDefinitionBase = CommandDefinitionBase> = {
  [commandId: string]: T;
};

export type PlatformAPIWebType = {
  getFriendlyName: () => string;
  getSystemFormChunk: (props: any) => any;
  getVirtualUserFormChunk: (props: any) => any;
  getVirtualUserInitData: (defaultInitData: any) => any;
};

export type ScreenshotParams = {
  clipToBody: boolean;
};

export type LoadResolvers<T = unknown> = () => T;

export type PlatformAPIType<T extends PlatformTypes = any> = {
  loadResolvers?: LoadResolvers<T["resolvers"]>;
  validateVersionRange: (versionedExecution: VersionedExecutionFunction) => boolean;
  waitForProcessing: any;
  getHostVersion: () => string;
  onStartRecording?: (sessionData: RunnerClientSessionData) => Promise<void>;
  getElementLabel?: (element: HTMLElement) => string | undefined;
  onStopRecording?: (sessionData: RunnerClientSessionData) => Promise<void>;
  isReady: () => Promise<void>;
  init?: () => Promise<void>;
  playerInit?: any;
  systemInit?: any;
  analyze?: (step: Step) => Promise<any>;
  beforeExecution?: (step: RunnerStep, scriptData: RunnerScriptData) => Promise<void>;
  getElementsByXPath?: (
    xpath: string,
    htmlDocument: Document,
    searchType: XPATH_SEARCH_TYPE,
  ) => Node[];
  getScreenshotParams?: () => ScreenshotParams;
  removeHiddenElementsOnInspect?: (element: HTMLElement) => void;
  createMessagesWatcher?: (onMessages: (messages: Message[]) => void) => void;
  generateSmartId?: (id: string, tag: string) => string | undefined;
  auth?: (tokens: VirtualUser["tokens"]) => Promise<boolean>;
  blockEvents?: () => boolean;
  blockBasicPlayerStepsUpdate?: () => boolean;
  processXpath?: (xpath: string) => string;
  getThrottling?: () => { afterExecution: number; beforeExecution: number };
  getCustomInspectElementValue?: (
    element: HTMLElement,
    defaultValue?: string,
  ) => string | undefined;
  validateGetElement?: (element: HTMLElement) => boolean;
  getElementMetadata?: (element: EventTarget) => PlatfromElementMetadata;
  getEventsToBlockOnInspect?: () => string[];
  getCustomEventsToRecord?: () => string[];
};

export type PlatformTranslations = {
  web: any;
  server: any;
};

export type PlatformTypes<T = unknown> = {
  resolvers: T;
};

export type PlatformPreloader = {
  id: string;
  preload: () => Promise<void> | void;
};

export type PlatformDefinition = {
  id: string;
  version: string;
  friendlyName: string;
  translations?: any;
};

export type Platform<T extends PlatformTypes = any> = PlatformDefinition & {
  api: PlatformAPIType<T>;
};

export type VUFormChunkApi = {
  prefix: string;
  values: Record<string, any>;
  isEditMode: true;
  defaultFormChunk: Record<string, any>;
  rememberSensitiveData: boolean;
};

export type PlatformWeb<T extends {} = {}> = PlatformDefinition & {
  api: {
    virtualUserExtension?: {
      getVirtualUserInitData: (defaultInitData: any) => any;
      getVirtualUserFormChunk: (
        api: VUFormChunkApi,
        components: FormChunkComponents,
        t: TranslationFunction,
      ) => () => any;
    };
    systemExtension?: {
      getDefaultMeta: () => T;
      getSystemFormChunk: (components: FormChunkComponents, t: TranslationFunction) => () => any;
    };
  };
};

type ServerAPI = any;

export type PlatformServer = PlatformDefinition & {
  api: {
    getAdditionalStartParam?: () => AdditionalJobParams;
    getScript?: ({ customUrl }: { customUrl?: string }) => ExternalScript;
    getVariables?: () => PlainObject<{
      value: any;
      name: string;
    }>;
    createPlayerPlatformEndpoints?: (
      creator: (
        type: "get" | "post",
        platformId: string,
        name: string,
        handler: (cache: any) => (params: any) => Promise<any>,
      ) => void,
      serverAPI: ServerAPI,
    ) => void;
  };
};

export const DEFAULT_PLATFORM_ID = "DEFAULT_PLATFORM";

type ParseFunction<T extends CommandDefinitionBaseType, P extends PlatformTypes = any> = (
  evt: Event,
  resolvers: P["resolvers"],
) => Partial<RecorderStep<T>> &
  Pick<
    RecorderStep<T>,
    "label" | "value" | "commandId" | "structureVersion" | "paths" | "labelKey"
  >;

export type RuleCondition = (evt: Event) => boolean;
export type RuleParse<
  T extends CommandDefinitionBaseType | undefined = any,
  P extends PlatformTypes = any,
> = [T] extends [CommandDefinitionBaseType]
  ? ParseFunction<T, P>
  : (evt: Event, resolvers: P["resolvers"]) => void;
export type NormalizeFunction = (steps: RecorderStep[], newStep: RecorderStep) => RecorderStep[];

export type RUNNER_MODES = "INSPECT";
export type Rule<
  T extends CommandDefinitionBaseType | undefined = any,
  P extends PlatformTypes = any,
> = {
  condition: RuleCondition;
  mode?: RUNNER_MODES; // we have only single mode right now
  getResolversData?: (resolvers: P["resolvers"], element: HTMLElement) => ResolversData;
  parse: RuleParse<T, P>;
  replace?: (step: RecorderStep) => any;
  normalize?: NormalizeFunction;
  priority?: number;
  name?: string;
  id?: string;
};

initDefaults();

export type NativeCondition = (page: Page, frame?: Frame) => Promise<boolean>;
