import { createRequest, getEndpoint } from "../requestCreator";

export const getDashboardDiskUsage = createRequest(getEndpoint("dashboard.sub.diskUsage"));

export const getDashboardRecentlyExecutedScripts = createRequest(
  getEndpoint("dashboard.sub.recentlyExecutedScripts"),
);

export const getDashboardSchedulerStats = createRequest(
  getEndpoint("dashboard.sub.schedulerStats"),
);

export const getDashboardPlannedSchedulers = createRequest(
  getEndpoint("dashboard.sub.plannedSchedulers"),
);
