"use client";
import { useTheme } from "@mui/material";
import {
  DefaultizedPieValueType,
  legendClasses,
  PieChart as MUIPieChart,
  pieArcLabelClasses,
  PieValueType,
} from "@mui/x-charts";

export type PieChartData = {
  id: number;
  value: number;
  label: string;
  color: "green" | "red" | "orange" | "gray" | "secondary" | "blue";
};

type PieChartProps = {
  data: PieChartData[];
  width?: number;
  height?: number;
  showLabels?: boolean;
  innerRadius?: number;
  showAsPercentage?: boolean;
  isLoading?: boolean;
  valueFormatter?: (value: PieValueType) => string;
};

type ArcLabelItem = Omit<DefaultizedPieValueType, "label"> & { label?: string };

export const PieChart = ({
  data,
  width = 320,
  height = 160,
  showLabels = true,
  innerRadius = 40,
  showAsPercentage = true,
  isLoading,
  valueFormatter,
}: PieChartProps) => {
  const theme = useTheme();

  const colorMap: { [key in PieChartData["color"]]: string } = {
    green: theme.palette.success[500] || "",
    red: theme.palette.error[500] || "",
    orange: theme.palette.warning[500] || "",
    gray: theme.palette.neutral[300] || "",
    secondary: theme.palette.secondary.main || "",
    blue: theme.palette.info[500] || "",
  };

  const totalValue = data.reduce((acc, item) => acc + item.value, 0);

  const getArcLabel = (item: ArcLabelItem) =>
    showAsPercentage ? `${((item.value / totalValue) * 100).toFixed(0)}%` : `${item.value}`;

  // do not show value 0 in pie chart
  const nonZeroData = data.filter((entry) => entry.value > 0);

  return (
    <MUIPieChart
      loading={isLoading}
      series={[
        {
          data: nonZeroData.map((entry) => ({
            ...entry,
            color: colorMap[entry.color],
          })),
          arcLabel: showLabels ? getArcLabel : undefined,
          innerRadius: innerRadius,
          valueFormatter: valueFormatter ? valueFormatter : (val) => `${val.value}`,
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "white",
          fontWeight: "400",
        },
        [`& .${legendClasses.mark}`]: {
          rx: "10",
          ry: "10",
        },
      }}
      width={width}
      height={height}
    />
  );
};
