"use client";
import { useTranslations } from "../translations/useTranslations";
import { ConfirmDialog, Typography } from "./materialUI";
import { OverlayProps, useOverlayStore } from "./OverlayManager";

interface CancelEditingDialogProps {
  disableEditing: () => void;
  onClose?: () => void;
}

export function CancelEditingDialog({
  disableEditing,
  onClose,
  isOpen,
}: OverlayProps<CancelEditingDialogProps>) {
  const { close } = useOverlayStore();
  const { t } = useTranslations("EditMode");

  return (
    <ConfirmDialog
      type="warning"
      onClose={() => {
        onClose?.();
        close(CancelEditingDialog);
      }}
      onConfirm={() => {
        disableEditing();
        close(CancelEditingDialog);
      }}
      withCloseIcon
      open={!!isOpen}
      title={t("confirmCancelEditing")}
      confirmButtonProps={{ color: "secondary", sx: { textTransform: "uppercase" } }}
      controls={{
        confirm: t("cancelEdit"),
        cancel: t("backToEdit"),
      }}
    >
      <Typography color="neutral900" fontWeight={500}>
        {t("confirmCancelEditingInfoFirst")}
      </Typography>
      <Typography color="neutral900" fontWeight={500}>
        {t("confirmCancelEditingInfoSecond")}
      </Typography>
    </ConfirmDialog>
  );
}
