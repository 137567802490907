"use client";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { AddAction, DeleteAction } from "../ActionsBar";
import { Typography } from "../materialUI";
import { FormInputsWrapper } from "./FormInputsWrapper";

type FormArrayFieldProps = {
  mode?: "table" | "list";
  disabled?: boolean;
  clearable?: boolean;
  title?: string | React.ReactNode;
  buttons?: { add?: string; remove?: string };
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderRow: (row: any, index: number) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getNewValue: () => any;
};

export const FormArrayField = ({
  mode = "table",
  buttons = {},
  disabled = false,
  clearable = false,
  title = "",
  name,
  renderRow,
  getNewValue,
}: FormArrayFieldProps) => {
  const { fields, remove, append } = useFieldArray({ name });

  const getAddButton = () =>
    !disabled ? (
      <AddAction
        variant={mode === "table" ? "icon" : "button"}
        onClick={() => {
          append(getNewValue());
        }}
      >
        {buttons.add}
      </AddAction>
    ) : null;

  const getRemoveAction = (index: number) =>
    !disabled ? (
      <DeleteAction
        variant={mode === "table" ? "icon" : "button"}
        onClick={() => remove(index)}
        disabled={!clearable && fields.length === 1}
      >
        {buttons.remove}
      </DeleteAction>
    ) : null;

  return (
    <>
      {mode === "table" && (
        <FormInputsWrapper
          direction="row"
          justifyContent="space-between"
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[100],
          })}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              paddingLeft: 2,
            }}
          >
            {title}
          </Typography>
          {getAddButton()}
        </FormInputsWrapper>
      )}

      {fields.map((row, index) => {
        return mode === "list" ? (
          <>
            <FormInputsWrapper gap={0.5}>{renderRow(row, index)}</FormInputsWrapper>
            <FormInputsWrapper key={index} direction="row" gap={0.5}>
              {index === fields.length - 1 && getAddButton()}
              {getRemoveAction(index)}
            </FormInputsWrapper>
          </>
        ) : (
          <FormInputsWrapper key={index} direction="row" gap={0.5} flexGrow={1}>
            <FormInputsWrapper gap={0.5}>{renderRow(row, index)}</FormInputsWrapper>
            {getRemoveAction(index)}
          </FormInputsWrapper>
        );
      })}
    </>
  );
};
