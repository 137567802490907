import {
  CreateADWorkItem,
  createIssueTrackingConfigurations,
  createIssueTrackingRule,
  createIssueTrackingTools,
  exportProjectsToTestPlans,
  exportScriptToTestCase,
  fetchIssueTrackingConfigurations,
  getAreas,
  getIntegrationRulesCount,
  getIssueTrackingConfigurations,
  getIssueTrackingRules,
  getIssueTrackingTools,
  getIterations,
  getItsProjects,
  getTestCases,
  getTestPlans,
  getWorkItemTypes,
  updateIssueTrackingConfigurations,
  updateIssueTrackingRule,
  updateIssueTrackingTools,
} from "../../api/endpoints";
import { createMutation, createQuery } from "./apiHooks";

export const ISSUE_TRACKING_CONFIGURATIONS_KEY = ["IssueTrackingToolsConfigurations", "Get"];

export const useGetIssueTrackingConfigurations = createQuery(getIssueTrackingConfigurations, {
  queryKey: ISSUE_TRACKING_CONFIGURATIONS_KEY,
});

export const useGetIssueTrackingTools = createQuery(getIssueTrackingTools, {
  queryKey: ["IssueTrackingTools", "Get"],
});

export const useCreateIssueTrackingTools = createMutation(createIssueTrackingTools, {
  mutationKey: ["IssueTrackingTools", "Create"],
});

export const useUpdateIssueTrackingTools = createMutation(updateIssueTrackingTools, {
  mutationKey: ["IssueTrackingTools", "Update"],
});

export const useCreateADWorkItem = createMutation(CreateADWorkItem, {
  mutationKey: ["IssueTrackingTools", "AD", "CreateWorkItem"],
});

export const useCountIntegrationRules = createQuery(getIntegrationRulesCount, {
  queryKey: ["IssueTrackingTools", "IssueTrackingRules", "Count"],
});

export const useGetIssueTrackingRules = createQuery(getIssueTrackingRules, {
  queryKey: ["IssueTrackingTools", "IssueTrackingRules", "Get"],
});

export const useUpdateIssueTrackingRule = createMutation(updateIssueTrackingRule, {
  mutationKey: ["IssueTrackingTools", "IssueTrackingRules", "Update"],
});

export const useCreateIssueTrackingRule = createMutation(createIssueTrackingRule, {
  mutationKey: ["IssueTrackingTools", "IssueTrackingRules", "Create"],
});

export const useCreateIssueTrackingConfigurations = createMutation(
  createIssueTrackingConfigurations,
  {
    mutationKey: ["IssueTrackingTools", "Configurations", "Create"],
  },
);

export const useUpdateIssueTrackingConfigurations = createMutation(
  updateIssueTrackingConfigurations,
  {
    mutationKey: ["IssueTrackingTools", "Configurations", "Update"],
  },
);

export const useFetchIssueTrackingConfigurations = createQuery(fetchIssueTrackingConfigurations, {
  queryKey: ["IssueTrackingTools", "Configurations", "Fetch"],
});

export const useGetItsProjects = createQuery(getItsProjects, {
  queryKey: ["IssueTrackingTools", "Projects", "Get"],
});

export const useGetTestPlans = createQuery(getTestPlans, {
  queryKey: ["IssueTrackingTools", "Integration", "TestPlans", "Get"],
});

export const useGetTestCases = createQuery(getTestCases, {
  queryKey: ["IssueTrackingTools", "Integration", "TestCases", "Get"],
});

export const useExportToTestPlans = createMutation(exportProjectsToTestPlans, {
  mutationKey: ["IssueTrackingTools", "Projects", "ExportToTestPlans"],
});

export const useGetWorkItemTypes = createQuery(getWorkItemTypes, {
  queryKey: ["IssueTrackingTools", "WorkItemsType", "Get"],
});

export const useGetIterations = createQuery(getIterations, {
  queryKey: ["IssueTrackingTools", "Iterations", "Get"],
});

export const useGetAreas = createQuery(getAreas, {
  queryKey: ["IssueTrackingTools", "Areas", "Get"],
});

export const useExportScriptToTestCase = createMutation(exportScriptToTestCase, {
  mutationKey: ["IssueTrackingTools", "Projects", "ExportToTestPlans"],
});
