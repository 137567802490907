import { Theme } from "@mui/material";
import { hexToRgba } from "../../utils/colors";

export const COLORS = {
  PRIMARY_BLUE: {
    50: "#F2F3F7",
    100: "#E5E7F0",
    200: "#CCD0E0",
    300: "#B3B9D1",
    400: "#99A1C2",
    500: "#8089B3",
    600: "#6672A3",
    700: "#4D5B94",
    800: "#334385",
    900: "#001466",
  },
  SECONDARY_BLUE: {
    50: "#EAEEF9",
    100: "#D5DCF2",
    200: "#ABBAE5",
    300: "#8097D9",
    400: "#5675CC",
    500: "#2C52BF",
    600: "#2647A6",
    700: "#203C8C",
    800: "#1A3173",
    900: "#152659",
  },
  PRIMARY_GREEN: {
    50: "#EAFAF7",
    100: "#D6F5EF",
    200: "#ACEBDF",
    300: "#83E2CF",
    400: "#59D8BF",
    500: "#30CEAF",
    600: "#24B59A",
    700: "#249C84",
    800: "#1E826E",
    900: "#186959",
  },
  NEUTRAL: {
    50: "#F3F3F4",
    100: "#E8E8E8",
    200: "#D1D1D1",
    300: "#9B9B9B",
    400: "#A2A2A3",
    500: "#8B8B8C",
    600: "#747476",
    700: "#5D5D5F",
    800: "#454548",
    900: "#171719",
  },
  ERROR: {
    50: "#FCE5E5",
    100: "#FACCCC",
    200: "#F59999",
    300: "#F06666",
    400: "#EB3333",
    500: "#E80000",
    600: "#CF0000",
    700: "#B50000",
    800: "#9C0000",
    900: "#690000",
  },
  WARNING: {
    50: "#FEF5E5",
    100: "#FDEBCC",
    200: "#FCD899",
    300: "#FAC466",
    400: "#F9B133",
    500: "#F79D00",
    600: "#DE8D00",
    700: "#C47D00",
    800: "#AB6D00",
    900: "#784C00",
  },
  SUCCESS: {
    50: "#E5F9F2",
    100: "#CCF2E4",
    200: "#99E5C9",
    300: "#66D9AF",
    400: "#33CC94",
    500: "#00BF79",
    600: "#00A669",
    700: "#008C59",
    800: "#007349",
    900: "#005939",
  },
  INFO: {
    50: "#E5EFFC",
    100: "#CCDFF9",
    200: "#99C0F3",
    300: "#66A0EC",
    400: "#3381E6",
    500: "#0061E0",
    600: "#0056C7",
    700: "#004BAD",
    800: "#004094",
    900: "#002A61",
  },
  SECONDARY_INFO: {
    50: "#F2EFFB",
    100: "#E4DFF7",
    200: "#CABEF0",
    300: "#AF9EE8",
    400: "#957DE1",
    500: "#7A5DD9",
    600: "#6C52BF",
    700: "#5D47A6",
    800: "#4F3C8C",
    900: "#322659",
  },
  // Dark mode theme draft palette
  PRIMARY_BLUE_DARK: {
    50: "#0D1026",
    100: "#0B0E22",
    200: "#090C1E",
    300: "#070A1A",
    400: "#050816",
    500: "#030612",
    600: "#02040E",
    700: "#01030A",
    800: "#000206",
    900: "#000000",
  },
  SECONDARY_BLUE_DARK: {
    50: "#8c8e95",
    100: "#7f8491",
    200: "#666f89",
    300: "#4c5a82",
    400: "#33467a",
    500: "#1a3172",
    600: "#162a63",
    700: "#132454",
    800: "#0f1d45",
    900: "#0c1635",
  },
  PRIMARY_GREEN_DARK: {
    50: "#8c9694",
    100: "#80938f",
    200: "#678d85",
    300: "#4e877c",
    400: "#358172",
    500: "#1c7b69",
    600: "#156c5c",
    700: "#155d4f",
    800: "#124e42",
    900: "#0e3f35",
  },
  NEUTRAL_DARK: {
    50: "#616161",
    100: "#5c5c5c",
    200: "#535353",
    300: "#3e3e3e",
    400: "#404041",
    500: "#373738",
    600: "#2e2e2f",
    700: "#252526",
    800: "#1b1b1c",
    900: "#09090a",
  },
  ERROR_DARK: {
    50: "#645b5b",
    100: "#645151",
    200: "#623d3d",
    300: "#602828",
    400: "#5e1414",
    500: "#5c0000",
    600: "#520000",
    700: "#480000",
    800: "#3e0000",
    900: "#2a0000",
  },
  WARNING_DARK: {
    50: "#65625b",
    100: "#655e51",
    200: "#64563d",
    300: "#644e28",
    400: "#634614",
    500: "#623e00",
    600: "#583800",
    700: "#4e3200",
    800: "#442b00",
    900: "#301e00",
  },
  SUCCESS_DARK: {
    50: "#5b6360",
    100: "#51605b",
    200: "#3d5b50",
    300: "#285646",
    400: "#14513b",
    500: "#004c30",
    600: "#00422a",
    700: "#003823",
    800: "#002e1d",
    900: "#002316",
  },
  INFO_DARK: {
    50: "#5b5f64",
    100: "#515963",
    200: "#3d4c61",
    300: "#28405e",
    400: "#14335c",
    500: "#002659",
    600: "#00224f",
    700: "#001e45",
    800: "#00193b",
    900: "#001026",
  },
  SECONDARY_INFO_DARK: {
    50: "#605f64",
    100: "#5b5962",
    200: "#504c60",
    300: "#463f5c",
    400: "#3b325a",
    500: "#302556",
    600: "#2b204c",
    700: "#251c42",
    800: "#1f1838",
    900: "#140f23",
  },
};

export const tableColors = [
  COLORS.NEUTRAL[200] || "white",
  COLORS.WARNING[200] || "red",
  COLORS.ERROR[200] || "red",
  COLORS.SECONDARY_BLUE[200] || "red",
  COLORS.PRIMARY_BLUE[200] || "red",
  COLORS.PRIMARY_BLUE[200] || "red",
  COLORS.PRIMARY_BLUE[200] || "red",
];

const getColor = (
  isDarkMode: boolean,
  darkColor: string,
  lightColor: string,
  breadcrumbLightColor: string,
  opacityOdd = 0.4,
  opacityEven = 0.6,
) => ({
  odd: isDarkMode ? hexToRgba(darkColor, opacityOdd) : hexToRgba(lightColor, 0.5),
  even: isDarkMode ? hexToRgba(darkColor, opacityEven) : lightColor,
  breadcrumb: isDarkMode ? darkColor : breadcrumbLightColor,
});

export function getNestedLinkedScriptsColors(theme: Theme) {
  const isDarkMode = theme.palette.mode === "dark";

  return [
    {
      odd: isDarkMode ? theme.palette.primary.dark : theme.palette.neutral[0],
      even: isDarkMode ? theme.palette.primary.main : theme.palette.neutral[50]!,
      breadcrumb: isDarkMode ? theme.palette.primary.light : theme.palette.neutral[600]!,
    },
    getColor(
      isDarkMode,
      theme.palette.secondary.dark,
      theme.palette.secondary[50]!,
      theme.palette.secondary[500]!,
    ),
    getColor(
      isDarkMode,
      theme.palette.success.dark,
      theme.palette.success[50]!,
      theme.palette.success[500]!,
    ),
    getColor(
      isDarkMode,
      theme.palette.info.dark,
      theme.palette.info[50]!,
      theme.palette.info[500]!,
    ),
    getColor(
      isDarkMode,
      theme.palette.warning.dark,
      theme.palette.warning[50]!,
      theme.palette.warning[500]!,
    ),
    getColor(
      isDarkMode,
      theme.palette.error.dark,
      theme.palette.error[50]!,
      theme.palette.error[500]!,
    ),
  ];
}

export function getNestedSchedulerGroupsColors(theme: Theme) {
  const isDarkMode = theme.palette.mode === "dark";

  return {
    0: {
      odd: isDarkMode ? theme.palette.primary.dark : theme.palette.neutral[0],
      even: isDarkMode ? theme.palette.primary.main : theme.palette.neutral[100]!,
    },
    1: getColor(isDarkMode, theme.palette.neutral.dark, theme.palette.neutral[50]!, "none"),
  };
}
