import {
  downloadDocumentation,
  downloadReport,
  generateReport,
  updateDocumentation,
} from "../../api/endpoints";
import { createMutation } from "./apiHooks";

export const useUpdateDocumentation = createMutation(updateDocumentation, {
  mutationKey: ["Documentations", "UpdateDocumentations"],
});

export const useDownloadDocumentations = createMutation(downloadDocumentation, {
  mutationKey: ["Documentations", "DownloadDocumentations"],
});

export const useGenerateReport = createMutation(generateReport, {
  mutationKey: ["Documentations", "GenerateReport"],
});

export const useDownloadReport = createMutation(downloadReport, {
  mutationKey: ["Documentations", "DownloadReport"],
});
