import { getEndpoint } from "@ea/shared_types/next/ea.endpoints";
import { RunnerMode } from "@ea/shared_types/next/ea.enums";
import { RUNNER_TYPE } from "@ea/shared_types/next/ea.runner.types";
import { createRequest } from "../requestCreator";

export const startRunner = createRequest(getEndpoint("runner.sub.start"), {
  bodyBuilder: (params: { scriptId: string; mode: string }) => {
    // WORK: review all missing start params (same thing in extesion)
    return JSON.stringify({
      isBackground: false,
      mode: params.mode,
      script: parseInt(params.scriptId, 10),
      runner: {
        type: RUNNER_TYPE.ADVANCED,
      },
    });
  },
});

export const startRunnerSession = createRequest(getEndpoint("runner.sub.start"));

export const startBackgroundRunner = createRequest(getEndpoint("execution.sub.run"), {
  bodyBuilder: (params) => {
    // WORK: review all missing start params (same thing in runner)
    return JSON.stringify({
      ...params,
      isBackground: true,
      mode: RunnerMode.PLAYER,
      runner: {
        type: RUNNER_TYPE.EXTENSION,
      },
    });
  },
});

export const syncSteps = createRequest(getEndpoint("runner.sub.next.sub.sync"));
export const tokens = createRequest(getEndpoint("runner.sub.next.sub.tokens"));
export const loaded = createRequest(getEndpoint("runner.sub.next.sub.loaded"));
export const reload = createRequest(getEndpoint("runner.sub.next.sub.reload"));
export const auth = createRequest(getEndpoint("runner.sub.next.sub.auth"));
export const heartbeat = createRequest(getEndpoint("runner.sub.heartbeat"));
export const playerEvent = createRequest(getEndpoint("runner.sub.next.sub.playerEvent"));
export const playerLog = createRequest(getEndpoint("runner.sub.next.sub.playerLog"));
export const playerStepLog = createRequest(getEndpoint("runner.sub.next.sub.playerStepLog"));
export const getSequence = createRequest(getEndpoint("runner.sub.next.sub.sequence"));
export const getInitialData = createRequest(getEndpoint("runner.sub.next.sub.data"));
