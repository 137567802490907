import { createRequest, getEndpoint } from "../requestCreator";

export const getDocumentations = createRequest(getEndpoint("documentations.sub.get"));
export const deleteDocumentation = createRequest(getEndpoint("documentations.sub.delete"));
export const updateDocumentation = createRequest(getEndpoint("documentations.sub.update"));
export const downloadDocumentation = createRequest(getEndpoint("documentations.sub.download"), {
  fileRequest: true,
});
export const generateReport = createRequest(getEndpoint("documentations.sub.reports.sub.generate"));
export const downloadReport = createRequest(
  getEndpoint("documentations.sub.reports.sub.download"),
  {
    fileRequest: true,
  },
);
