"use client";
import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from "@mui/icons-material";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { useImageDimensions } from "./useImageDimensions";
import { useKeyboardNavigation } from "./useKeyboardNavigation";

export type ExecutionScreenshot = {
  path: string;
  caption: string;
  stepId: number;
};

export interface ScreenshotGalleryProps {
  screenshots: ExecutionScreenshot[];
  startIndex?: number;
}

export const ScreenshotGallery = ({ screenshots, startIndex }: ScreenshotGalleryProps) => {
  const [index, setIndex] = useState(startIndex || 0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const imageDimensions = useImageDimensions(containerRef, imageRef);

  const openLightbox = () => setIsLightboxOpen(true);
  const closeLightbox = () => setIsLightboxOpen(false);

  const handlePrevious = useCallback(
    () => setIndex((prev) => (prev > 0 ? prev - 1 : screenshots.length - 1)),
    [screenshots.length],
  );

  const handleNext = useCallback(
    () => setIndex((prev) => (prev + 1) % screenshots.length),
    [screenshots.length],
  );

  useKeyboardNavigation(handlePrevious, handleNext);

  const slideImages = screenshots.map((screenshot) => ({
    src: screenshot.path,
    caption: screenshot.caption,
  }));

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {screenshots[index] && (
        <Typography sx={{ mb: 5 }} fontWeight={500} variant="h5" color="neutral900">
          {screenshots[index].caption}
        </Typography>
      )}
      <Box
        ref={containerRef}
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {screenshots[index] && (
          <Box
            component="img"
            ref={imageRef}
            src={screenshots[index].path}
            onClick={openLightbox}
            sx={{
              width: `${imageDimensions.width}px`,
              height: `${imageDimensions.height}px`,
              objectFit: "contain",
              cursor: "pointer",
            }}
          />
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "28px",
            mt: 2,
            p: 0.5,
          }}
        >
          <IconButton onClick={handlePrevious} size="small" disabled={screenshots.length === 1}>
            <ChevronLeft fontSize="large" />
          </IconButton>
          <IconButton onClick={openLightbox} size="medium">
            <ZoomIn fontSize="medium" />
          </IconButton>
          <IconButton onClick={openLightbox} size="medium">
            <ZoomOut fontSize="medium" />
          </IconButton>
          <IconButton onClick={handleNext} size="small" disabled={screenshots.length === 1}>
            <ChevronRight fontSize="large" />
          </IconButton>
        </Paper>
      </Box>
      <Lightbox
        slides={slideImages}
        render={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          slide: ({ slide }: { slide: any }) => (
            <Box
              sx={{
                maxWidth: "100%",
                maxHeight: "90%",
                gap: 1,
                flexDirection: "column",
                display: "flex",
                overflow: "auto",
              }}
            >
              <Typography sx={(theme) => ({ color: theme.palette.grey[500] })}>
                {slide.caption}
              </Typography>
              <img src={slide.src} alt={slide.caption} />
            </Box>
          ),
        }}
        open={isLightboxOpen}
        index={index}
        close={closeLightbox}
        plugins={[Zoom]}
        animation={{ swipe: 0 }}
      />
    </Box>
  );
};
