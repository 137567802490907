import {
  getDashboardDiskUsage,
  getDashboardPlannedSchedulers,
  getDashboardRecentlyExecutedScripts,
  getDashboardSchedulerStats,
} from "../../api/endpoints";
import { createQuery } from "./apiHooks";

export const useGetDashboardDiskUsage = createQuery(getDashboardDiskUsage, {
  queryKey: ["Dashboard", "DiskUsage"],
});

export const useGetDashboardRecentlyExecutedScripts = createQuery(
  getDashboardRecentlyExecutedScripts,
  {
    queryKey: ["Dashboard", "RecentlyExecutedScripts"],
  },
);
export const useGetDashboardSchedulerStats = createQuery(getDashboardSchedulerStats, {
  queryKey: ["Dashboard", "SchedulerStats"],
});

export const useGetDashboardPlannedSchedulers = createQuery(getDashboardPlannedSchedulers, {
  queryKey: ["Dashboard", "PlannedSchedulers"],
});
